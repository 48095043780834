import React,{useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux'
// import {Row,Col, ListGroup,Card,Image } from 'react-bootstrap'
import Product from '../components/Product'
import  Paginate from '../components/Paginate'
import { Link } from 'react-router-dom';
// import products from '../products'
// import axios from 'axios'
import { listProducts} from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import ProductCarousel from '../components/ProductCarousel'
import ProductSlickslider from '../components/ProductSlickslider'
import Sidebar from '../components/Sidebar'
import ListGroupheader from '../components/ListGroupheader'
import NewProductList from '../components/NewProductList'
import NewProductHeader from '../components/NewProductHeader'
import TopProductHeader from '../components/TopProductHeader'
import AllCategoryScreen from '../screens/AllCategoryScreen'
// import Items from '../components/Items';
import LatestProductList from '../components/LatestProductList';
import SearchBox from '../components/SearchBox';
import { RiSearch2Line } from 'react-icons/ri';
import SearchBox3 from '../components/SearchBox3'

function HomeScreens(history) {
    // const [products,setProducts] =useState([]);
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const {error, loading,products,page,pages} =productList

    let keyword =history.location.search
    // console.log(keyword)
    useEffect(() =>{
        // async function fetchProducts(){
        //     const {data} = await axios.get('/api/products/')
        //     setProducts(data)
        // }
        // fetchProducts()
        //now we can have our keyword sent to the backend
        dispatch(listProducts(keyword))
    },[dispatch,keyword])
  
    return (

    //     <section className="page-section bg-secondary text-white mb-0" >
    //     <div className="container">
    //         <div className="row">
    //             <div className="col-md-3">
    //              <ListGroupheader />
    //                 {/* <Sidebar/> */}
                    
    //             </div>
    //          <div className="col-md-9">
    //                 <div> </div>
    //                 {!keyword && <ProductCarousel/>}
    //             </div> 

    //             <div className="divider-custom">
    //                 <div className="divider-custom-line"></div>
    //             </div>
    //             <div className="row">
    //             </div>    
    //         </div>
    //         <div className="col-md-12">
    //             <NewProductHeader/>
    //             <NewProductList/>
    //                 <div className="card" style={{ color:'white', backgroundColor: '#3CB371'}}>
    //                         <h5 class="card-header text-center">POPULAR THIS SEASON</h5>
    //                 </div>
    //                 <div class="row">
    //                         <div class="col-sm-6">
    //                             <div class="card" 
    //                             style={{ 
    //                                 backgroundImage: `url(${'/images/shoppingClothing7.jpg'})`,
    //                                 backgroundRepeat: 'no-repeat',
    //                                 height:'250px'
    //                                 }}>
    //                             <div class="card-body">
                                  
    //                             </div>
    //                             </div>
    //                         </div>
    //                         <div class="col-sm-6">
    //                             <div class="card">
    //                             <div class="card-body"
    //                                 style={{ 
    //                                     backgroundImage: `url(${ '/images/background1.jpg'})`,
    //                                      backgroundRepeat: 'no-repeat',
                                      
    //                                     height:'250px'
    //                                     }}>
                                    
    //                             </div>
    //                             </div>
    //                         </div>
    //                         </div>
    //         </div>
    //         {loading ? <Loader/>
    //             :error ? <Message variant='danger'>{error}</Message>
    //                 :
    //             <div>
    //                 <Row>
    //                     <ProductSlickslider/>
    //                 </Row>
    //                 <Paginate page={page} pages={pages} keyword={keyword} />
               
    //             </div>        
    //         }

    //     <Row>
    //         <TopProductHeader/>              
    //         <ProductSlickslider/>
   
    //            </Row>

    //     </div>
    // </section>
<div className='bg-secondary'>

    <section className="hero">
    <div className="container">
        <div className="row">
            <div className="col-lg-3">
                <div className="hero__categories">
                    <div className="hero__categories__all">
                        <i className="fa fa-bars"></i>
                        <span>All departments</span>
                    </div>
                    <ul>
                        <li><Link to={`/categoryBlouse`}>Blouse</Link></li>
                        <li><Link to={`/categoryDress`}>Dresses</Link></li>
                        <li><Link to={`/categoryThrowOn`}>Throw Ons</Link></li>
                        <li><Link to={`/categoryTshirts`}>T-Shirts</Link></li>
                        <li><Link to={`/`}>Sweaters</Link></li>
                        <li><Link to={`/`}>Bottom</Link></li>
                        <li><Link to={`/`}>Coats</Link></li>
                        <li><Link to={`/`}>Uniforms</Link></li>
                        <li><Link to={`/`}>Shoes</Link></li>
                        {/* <li><a href="#">Oatmeal</a></li>
                        <li><a href="#">Fresh Bananas</a></li> */}
                    </ul>
                </div>
            </div>
            <div className="col-lg-9">
                <div className="hero__search">
                    <div><SearchBox3 placeholder="Search products and categories" data ={products}/></div>
                    
                    <div className="hero__search__phone">
                        <div className="hero__search__phone__icon">
                            <i className="fa fa-phone"></i>
                        </div>
                        <div className="hero__search__phone__text">
                            <h5>+256781544517</h5>
                            <span>support 24/7 time</span>
                        </div>
                    </div>
                </div>
                {/* <div class="hero__item set-bg" data-setbg="assets/img/hero/shoppingClothing11.jpg">
                    <div class="hero__text">
                        <span>FASHIONAL DRESSES</span>
                        <h2>Dresses <br />100% cotton</h2>
                        <p>Free Pickup and Delivery Available</p>
                        <a href="#" class="primary-btn">SHOP NOW</a>
                    </div> 
                </div>*/}
                <div>
                {!keyword && <ProductCarousel/>}
                
                </div>
            </div>
        </div>
    </div>
</section>

        <section className="categorie">
                <div className="container">
                    <div className="row">
                    
                        <div className="categories__slider owl-carousel">
                            <div className="col-lg-3">
                               <div className="categories__item set-bg" data-setbg="assets/img/categories/Dresses01.jpg"> 
                                    <h5><Link to={`/categoryDress`}>Dresses</Link></h5>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="categories__item set-bg" data-setbg="assets/img/categories/Blouse01.jpg">
                                    <h5><Link to={`/categoryBlouse`}>Blouse</Link></h5>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="categories__item set-bg" data-setbg="assets/img/categories/Sweaters01.jpg">
                                    <h5><a href="#">Sweaters</a></h5>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="categories__item set-bg" data-setbg="assets/img/categories/T-shirts012.jpg">
                                    <h5><Link to={`/categoryTshirts`}>T-Shirts</Link></h5>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="categories__item set-bg" data-setbg="assets/img/categories/Skirt.jpg">
                                    <h5><a href="#">Skirts</a></h5>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>   
        </section>

<section className="categories">
    < div className='container'>        
                    
<div className="card" style={{ color:'white', backgroundColor: '#3CB371'}}>
    <h5 class="card-header text-center">CHECK OUT OUR NEW PRODUCTS</h5>
</div>
</div> 

</section>
<section className="categories">
<div className="container">
        <LatestProductList/>
    </div>
</section>
<section className="categories">
    <div className="container">
        <div className="card" style={{ color:'white', backgroundColor: '#3CB371'}}>
              <h5 className="card-header text-center">Upgrade your Wardrobe</h5>
        </div>
    </div>
</section>
<div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src="assets/img/banner/banner02.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="banner__pic">
                                <img src="assets/img/banner/banner05.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <section className="latest-product spad ">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Latest Products</h4>
                                <div className="latest-product__slider owl-carousel">
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/new_dress01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New Dresses</h6>
                                                <span></span>
                               ``             </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/skirt015.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New Skirts</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New ThrowOns</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/jacket04.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New Jackets</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/coats01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New Coats</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/Fab01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>New Fabric</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Top Rated Products</h4>
                                <div className="latest-product__slider owl-carousel">
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>Dresses</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span>$30.00</span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="latest-product__text">
                                <h4>Review Products</h4>
                                <div className="latest-product__slider owl-carousel">
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="latest-prdouct__slider__item">
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                        <a href="#" className="latest-product__item">
                                            <div className="latest-product__item__pic">
                                                <img src="assets/img/latest-product/ThrowOns01.jpg" alt="" />
                                            </div>
                                            <div className="latest-product__item__text">
                                                <h6>HaffyFashions</h6>
                                                <span></span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    </div>

    )
}

export default HomeScreens
