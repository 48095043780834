import {React,useState,useEffect} from 'react'
import { useDispatch,useSelector  } from 'react-redux'
import LatestProduct from '../components/LatestProduct'
import { listProducts} from '../actions/productActions'
import  Paginate from '../components/Paginate'
export default function LatestProductList() {
    const productList = useSelector(state => state.productList)
	const {error, loading,products,page,pages} =productList
    const  dispatch = useDispatch()
    
     useEffect(()=>{
         dispatch(listProducts())
      },[dispatch])
  return (
    <LatestProduct products={products.filter((product)=> product.brand==='New')} title="Dresses"/>
  )
}
