import React from 'react'
import { Navbar,Nav,Container,Row, NavDropdown} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch,useSelector } from 'react-redux'
import  SearchBox  from './SearchBox'
import { logout } from '../actions/userActions'
import { Link } from 'react-router-dom'
function Header(cartItems) {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo} = userLogin
    const dispatch = useDispatch()
    const logoutHandler =() =>{
        //console.log('Logout')
        //instead dispatch, we going to dispatch our logout acton
        dispatch(logout())
    }
    return (
		<header className="header bg-dark">
            {/* <Navbar style={{backgroundColor:"#2E8B57"}} variant="dark" expand="lg" collapseOnSelect >
                {/* the color was bg="dark"  fixed="top" 
                <Container>
                    <LinkContainer to='/'>
                        <Navbar.Brand>JSMFASHIONS</Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <SearchBox/> 
                    <Nav className="ms-auto">
                        <LinkContainer to='/cart'>
                            <Nav.Link ><i className="fas fa-shopping-cart"></i>Cart</Nav.Link>
                        </LinkContainer>
                        {userInfo ? (
                            <NavDropdown title={userInfo.name} id='username'>
                                <LinkContainer to='/profile'>
                                    <NavDropdown.Item>Profile</NavDropdown.Item>
                                </LinkContainer>
                                <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        ):(
                            <LinkContainer to='/login'>
                                <Nav.Link href="/login"><i className="fas fa-user"></i>Login</Nav.Link>
                            </LinkContainer>
                        )}

                        {userInfo && userInfo.isAdmin &&(
                            <NavDropdown title='Admin' id='adminmenu'>
                            <LinkContainer to='/admin/userlist'>
                                <NavDropdown.Item>Users</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/admin/productlist'>
                                <NavDropdown.Item>Products</NavDropdown.Item>
                            </LinkContainer>
                            <LinkContainer to='/admin/orderlist'>
                                <NavDropdown.Item>Orders</NavDropdown.Item>
                            </LinkContainer>
                        </NavDropdown>

                        )}
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
            <div className="humberger__menu__overlay"></div>
            <div className="humberger__menu__wrapper bg-danger">
                <div className="humberger__menu__logo">
                    <a href="#" className="logo">JSM fashions</a>
                </div>
                <div className="humberger__menu__cart">
                    <ul>
                        {/* <li><a href="#"><i className="fa fa-heart"></i> <span>1</span></a></li> */}
                        <li><a href="#"><i className="fa fa-shopping-bag"></i></a></li>
                    </ul>
                    {/* <div className="header__cart__price">item: <span>Ugs 150.00</span></div> */}
                </div>
                <div className="humberger__menu__widget">
                    <div className="header__top__right__language">
                        <img src="assets/img/language1.png" alt="" />
                        <div>English</div>
                        <span className="arrow_carrot-down"></span>
                        <ul>
                            <li><a href="#">Luganda</a></li>
                            <li><a href="#">English</a></li>
                        </ul>
                    </div>
                    <div className="header__top__right__auth">
                    {userInfo ? (
                                    <div className="header__top__right__language" >
                                        <div id='username' style={{textTransform:'uppercase'}}>{userInfo.name}</div>
                                        <span className="arrow_carrot-down"></span>
                                        <ul>
                                            <li><Link to={'/profile'}>Profile</Link></li>
                                            <div onClick={logoutHandler}>
                                            <li><NavDropdown.Item style={{color:'white'}} onClick={logoutHandler}>Logout</NavDropdown.Item></li>
                                            </div>
                                            
                                        </ul>
                                    </div>
                                    ):(
                                        <div className="header__top__right__auth">
                                         <Nav.Link href="/login"><i className="fa fa-user"></i> Login</Nav.Link>
                                        </div>
                                    )
                                    }  
                                    {
                                        userInfo && userInfo.isAdmin &&(
                                            <div className="header__top__right__language" >
                                            <div id='adminmenu'>ADMIN</div>
                                            <span class="arrow_carrot-down"></span>
                                            <ul>
                                                <li><Link to={'/admin/userlist'}>Users</Link></li>
                                                <li><Link to={'/admin/productlist'}>Products</Link></li>
                                                <li><Link to={'/admin/orderlist'}>Orders</Link></li>
                                                
                                            </ul>
                                        </div>    
                                        )
                                    }
                    </div>
                </div>
                <nav className="humberger__menu__nav mobile-menu">
                    <ul>
                    <li className="active"><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'categoryProducts'}>Shop</Link></li>
                                    <li><a href="#">Pages</a>
                                        <ul className="header__menu__dropdown">
                                            <li><a href="#">Shop Details</a></li>
                                            <li><Link to='/cart'>Shopping Cart</Link></li>
                                            <li><a href="#">Check Out</a></li>
                                            <li><a href="#">Blog Details</a></li>
                                        </ul>
                                    </li>
                        {/* <li><a href="#">Blog</a></li> */}
                        <li><a href="#">Contact</a></li>
                    </ul>
                </nav>
                <div id="mobile-menu-wrap"></div>
                <div className="header__top__right__social">
                    <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-linkedin"></i></a>
                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                </div>
                <div className="humberger__menu__contact">
                    <ul>
                        <li><i className="fa fa-envelope"></i> haffy@gmail.com</li>
                        <li>Shipping for all Order as low as Ugs 15,000</li>
                    </ul>
                </div>
            </div>

            <div className="header__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="header__top__left">
                                    <ul>
                                        <li><i className="fa fa-envelope"></i>haffy@gmail@gmail.com</li>
                                        <li>Shipping for all Order as low as Ugs 15,000</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="header__top__right">
                                    <div className="header__top__right__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-linkedin"></i></a>
                                        <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                    </div>
                                    <div className="header__top__right__language">
                                        <img src="assets/img/language1.png" alt="" />
                                        <div>English</div>
                                        <span className="arrow_carrot-down"></span>
                                        <ul>
                                            <li><a href="#">Luganda</a></li>
                                            <li><a href="#">English</a></li>
                                        </ul>
                                        
                                    </div>
                                    {userInfo ? (
                                    <div className="header__top__right__language" >
                                        <div id='username' style={{textTransform:'uppercase'}}>{userInfo.name}</div>
                                        <span className="arrow_carrot-down"></span>
                                        <ul>
                                            <li><Link to={'/profile'}>Profile</Link></li>
                                            <div onClick={logoutHandler}>
                                            <li><NavDropdown.Item style={{color:'white'}} onClick={logoutHandler}>Logout</NavDropdown.Item></li>
                                            </div>
                                            
                                        </ul>
                                    </div>
                                    ):(
                                        <div className="header__top__right__auth">
                                         <Nav.Link href="/login"><i className="fa fa-user"></i> Login</Nav.Link>
                                        </div>
                                    )
                                    }  
                                    {
                                        userInfo && userInfo.isAdmin &&(
                                            <div className="header__top__right__language" >
                                            <div id='adminmenu'>ADMIN</div>
                                            <span class="arrow_carrot-down"></span>
                                            <ul>
                                                <li><Link to={'/admin/userlist'}>Users</Link></li>
                                                <li><Link to={'/admin/productlist'}>Products</Link></li>
                                                <li><Link to={'/admin/orderlist'}>Orders</Link></li>
                                                
                                            </ul>
                                        </div>    
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="header__logo">
                                <Link to={'/'} className="logo">JSM fashions</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <nav className="header__menu">
                                <ul>
                                    <li className="active"><Link to={'/'}>Home</Link></li>
                                    <li><Link to={'categoryProducts'}>Shop</Link></li>
                                    <li><a href="#">Pages</a>
                                        <ul className="header__menu__dropdown">
                                            <li><a href="#">Shop Details</a></li>
                                            <li><Link to='/cart'>Shopping Cart</Link></li>
                                            <li><a href="#">Check Out</a></li>
                                            <li><a href="#">Blog Details</a></li>
                                        </ul>
                                    </li>
                                    {/* <li><a href="#">Blog</a></li> */}
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="header__cart">
                                <ul>
                                    {/* <li><a href="#"><i className="fa fa-heart"></i> <span>1</span></a></li> */}
                                    {/* <li><Link to='/cart'><i className="fa fa-shopping-bag"></i> <span>3</span>Your Cart</Link></li> */}
                                    <li><Link to='/cart'><i className="fa fa-shopping-bag"></i>Your Cart</Link></li>
                                </ul>
                                {/* <div className="header__cart__price">item: <span>Ugs 150.00</span></div> */}
                            </div>
                        </div>
                    </div>
                    <div className="humberger__open">
                        <i style={{ color:'white'}}className="fa fa-bars"></i>
                    </div>
                </div>

		</header>
    )
}

export default Header
