import React from 'react'
import { Link } from 'react-router-dom';
function Footer() {
    return (
        <footer class="footer spad">	
			{/* <div id="bottom-footer" className="section" >
				<div className="container">	
					<div className="row">
						<div class="col-md-3 col-xs-6">
						<div class="footer">
								<h3 class="footer-title">location</h3>
								{/* <p> JSM fashions is your global online store that delivers latest fashion apparel for you! We have dresses, tops, bottoms and swimwear for girls and ladies. 
									 Enjoy your shopping at haffyfashions.com. </p> 
								<ul class="footer-links">
									<li><a href="#"><i class="fa fa-map-marker"></i>Opposite Pastor Sempa Church</a></li>
									<li><a href="#"><i class="fa fa-phone"></i>+256-704-160-994</a></li>
									<li><a href="#"><i class="fa fa-envelope-o"></i>wmukasa@gmail.com</a></li>
								</ul>
							</div>
						</div>
						<div class="col-md-3 col-xs-6">
							<div class="footer">
								<h3 class="footer-title">SITE INFO</h3>
								<ul class="footer-links">
									<li><a href="#">About Us</a></li>
									<li><a href="#">Privacy Policy</a></li>
									<li><a href="#">Terms & Conditions</a></li>
									<li><a href="#">Sitemap</a></li>
								</ul>
							</div>
						</div>

						<div class="col-md-3 col-xs-6">
							<div class="footer">
								<h3 class="footer-title">CUSTOMER Service</h3>
								<ul class="footer-links">
									<li><a href="#">Contact Us</a></li>
									<li><a href="#">My Account</a></li>
									<li><a href="#">Track Order</a></li>
									<li><a href="#">Cancel Order</a></li>
									<li><a href="#">Editing Delivery Adress</a></li>
									
								</ul>
							</div>
						</div>
						<div class="col-md-3 col-xs-6">
							<div class="footer">
								<h3 class="footer-title">HELP CENTER</h3>
								<ul class="footer-links">
									<li><a href="#">Q&A</a></li>
									<li><a href="#">Payment Method</a></li>
									<li><a href="#">Delivery</a></li>
									<li><a href="#">Returns and Exchange</a></li>
								</ul>
							</div>
						</div>
						<div className="text-center py-3"> 
							<span className="copyright">
							Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | 2022 <i class="fa fa-heart-o" aria-hidden="true"></i> by Mukasa William
							</span>
						</div>
					</div>		
				</div>
			</div>	 */}
			<div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="footer__about">
                                <div class="footer__about__logo">
                                    <a href="#" class="logo">JSM Fashions</a>
                                </div>
                                <ul>
                                    <li>Address: Mutundwe Near Fellowship Church</li>
                                    <li>Phone: +256781544517</li>
                                    <li>Email: wmukasa@gmail.com</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-1">
                            <div class="footer__widget">
                                <h6>Useful Links</h6>
                                <ul>
                                    <li><Link to={`/about_us`}>About Us</Link></li>
                                    <li><a href="#">About Our Shop</a></li>
                                    <li><a href="#">Secure Shopping</a></li>
                                    <li><a href="#">Delivery infomation</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Our Sitemap</a></li>
                                </ul>
                                <ul>
                                    <li><a href="#">Who We Are</a></li>
                                    <li><a href="#">Our Services</a></li>
                                    <li><a href="#">Projects</a></li>
                                    <li><a href="#">Contact</a></li>
                                    <li><a href="#">Innovation</a></li>
                                    <li><a href="#">Testimonials</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="footer__widget">
                                <h6>Join Our Social Media Plateforms</h6>
                                <p>Get E-mail updates about our latest shop and special offers.</p>
                                <form action="#">
                                    <input type="text" placeholder="Enter your mail" />
                                    <button type="submit" class="site-btn">Subscribe</button>
                                </form>
                                <div class="footer__widget__social">
                                    <a href="#"><i class="fa fa-facebook"></i></a>
                                    <a href="#"><i class="fa fa-instagram"></i></a>
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                    <a href="#"><i class="fa fa-pinterest"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="footer__copyright">
                                <div class="footer__copyright__text"><p>
          							Copyright &copy;2024 All rights reserved <i class="fa fa-heart" aria-hidden="true"></i> by <a href="#" target="_blank">JSM Fashions</a>
        							</p></div>
                                <div class="footer__copyright__payment"><img src="assets/img/payment-item.png" alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                
        </footer>
    )
}

export default Footer
