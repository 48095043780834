import {React,useState,useEffect} from 'react'
import Rating from '../components/Rating'
import { Link } from 'react-router-dom';
import {Breadcrumb,Row,Col,Image} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch,useSelector  } from 'react-redux'
// import { myCategory} from '../actions/productActions'
import SearchBox2 from '../components/SearchBox2';

function AllCategoryScreen() {
    const dispatch = useDispatch()
 
	const productList = useSelector(state => state.productList)
	const {products} =productList
 
 return (
	<section className="page-section bg-secondary text-white mb-0" >
	<div className="container">

       <div className="row">

					<div id="aside" className="col-md-3">
						<div className="aside">
						<div class="hero__categories">
                    <div class="hero__categories__all">
                        <i class="fa fa-bars"></i>
                        <span>All departments</span>
                    </div>
                    <ul>
                        <li><Link to={`/categoryBlouse`}>Blouse</Link></li>
                        <li><Link to={`/categoryDress`}>Dresses</Link></li>
                        <li><Link to={`/categoryThrowOn`}>Throw Ons</Link></li>
                        <li><Link to={`/categoryTshirts`}>T-Shirts</Link></li>
                        <li><Link to={`/`}>Sweaters</Link></li>
                        <li><Link to={`/`}>Bottom</Link></li>
                        <li><Link to={`/`}>Coats</Link></li>
                        <li><Link to={`/`}>Uniforms</Link></li>
                        <li><Link to={`/`}>Shoes</Link></li>
                        {/* <li><a href="#">Oatmeal</a></li>
                        <li><a href="#">Fresh Bananas</a></li> */}
                    </ul>
                </div>

				<div class="hero__categories">
                    <div class="hero__categories__all">
                        <i class="fa fa-bars"></i>
                        <span>All departments</span>
                    </div>
                    <ul>
                        <li><Link to={`/categoryBlouse`}>Blouse</Link></li>
                        <li><Link to={`/categoryDress`}>Dresses</Link></li>
                        <li><Link to={`/categoryThrowOn`}>Throw Ons</Link></li>
                        <li><Link to={`/categoryTshirts`}>T-Shirts</Link></li>
                        <li><Link to={`/`}>Sweaters</Link></li>
                        <li><Link to={`/`}>Bottom</Link></li>
                        <li><Link to={`/`}>Coats</Link></li>
                        <li><Link to={`/`}>Uniforms</Link></li>
                        <li><Link to={`/`}>Shoes</Link></li>
                        {/* <li><a href="#">Oatmeal</a></li>
                        <li><a href="#">Fresh Bananas</a></li> */}
                    </ul>
                </div>
					</div>
					</div>


          <div id="store" className="col-md-9">

						<div className="store-filter clearfix">
							<div className="store-sort">
								<label>
									Sort By:
									<select className="input-select">
										<option value="0">Popular</option>
										<option value="1">Position</option>
									</select>
								</label>

								<label>
									Show:
									<select className="input-select">
										<option value="0">20</option>
										<option value="1">50</option>
									</select>
								</label>
								<SearchBox2/>
							</div>
							{/* <ul className="store-grid">
								<li className="active"><i className="fa fa-th"></i></li>
								<li><a href="#"><i className="fa fa-th-list"></i></a></li>
							</ul> */}
						</div>

           			<div className="row">
						<div className="clearfix visible-lg visible-md"></div>
							<div className="clearfix visible-lg visible-md visible-sm visible-xs"></div>
							{
			        products.map(product=>(
							<div className='col-md-4 col-xs-6' >
								<div className='product' key={product._id}>
									<div className='product-img'>
										<Link to={`/products/${product._id}`}>
											<Image src={product.image} alt={product.name} fluid/>
										</Link>
									   
									</div>
									<div className="product-body">
										<p className="product-category">{product.category}</p>
										<h6 className="product-name"><Link to={`/products/${product._id}`}>{product.name}</Link></h6>
										<h4 className="product-price">Shs {product.price}</h4>
										<div className="product-rating">

											<Rating value={product.rating} text={`${product.numReviews} reviews`} color={'#f8e825'} />
										</div>
										<div className="product-btns">
											{/* <button className="add-to-wishlist"><i className="fa fa-heart-o"></i><span className="tooltipp">add to wishlist</span></button>
											<button className="add-to-compare"><i className="fa fa-exchange"></i><span className="tooltipp">add to compare</span></button> 
											<button className="quick-view"><i className="fa fa-eye"></i><span className="tooltipp">quick view</span></button>*/}
										</div>
									</div>
									<div className="add-to-cart">
										<button className="add-to-cart-btn"><i className="fa fa-shopping-cart"></i> add to cart</button>
									</div>
								</div>
							</div>
					)
					)} 

						</div>
            </div>


           </div>
    </div>
	</section>

  )
}

export default AllCategoryScreen




	





