import React,{useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Carousel,Image} from 'react-bootstrap'
import Loader from './Loader'
import Message from './Message'
import {listTopProducts} from '../actions/productActions'
function ProductCarousel() {
    const dispatch = useDispatch()

    const productTopRated = useSelector(state => state.productTopRated)
    const {error,loading,products} =productTopRated

    useEffect(()=>{
        dispatch(listTopProducts())
    },dispatch)
    return ( loading ? <Loader/>//if we are loading , lets just put that loader
        :error//else we check if we have an error
        ? <Message variant=''>{error}</Message>
        :(
            <Carousel pause='hover' 
            //  style={{backgroundColor:"#2E8B57"}}
             style={{ 
                backgroundImage: `url(${process.env.PUBLIC_URL + '/images/shoppingClothing11.jpg'})`,
                backgroundRepeat: 'no-repeat',
                
                }}
                >
             
                { products.map(product =>(//we map through products the color was bg-dark
                    <Carousel.Item key={product._id}>

                        <div class="hero__item set-bg" >
                            <div class="hero__text">
                                <span>FASHIONAL {product.name}</span>
                                <h2>{product.category} <br />{product.materialType}</h2>
                                <h6>Free Pickup and Delivery Available</h6>
                                <a href="#" class="primary-btn">SHOP NOW</a>
                            </div> 
                        </div>
                        {/* react requires a key  */}
                        <Link to={`/products/${product._id}`}>
                            {/* <Image src={`images/${product.image}`}alt={product.name} fluid/> */}
                            <Image src={product.image}alt={product.name} fluid/>
                            <Carousel.Caption className='carousel.caption'>
                                <h4>{product.name}({product.price}/=)</h4>
                            </Carousel.Caption>
                        </Link>


                    </Carousel.Item>
                ))}
            </Carousel>
        )
    )
}

export default ProductCarousel
