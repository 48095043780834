import React,{useState} from 'react'
// import './Search.css';
import { Link } from 'react-router-dom';
import '../components/Search3.css'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from  "@material-ui/icons/Close";
import { useHistory } from 'react-router-dom'

function SearchBox3({placeholder,data}) { 
    let history = useHistory()
    const [filteredData,setfilteredData] = useState([]);
    const [wordEntered,setwordEntered] = useState("");
    const handleFilter =(event) =>{
        // const searchWord = event.target.value;
        const keyword = event.target.value;
        // setwordEntered(searchWord);
        setwordEntered(keyword);
        const newFilter = data.filter((value)=>{
            // return value.name.toLowerCase().includes(searchWord.toLowerCase())
            return value.name.toLowerCase().includes(keyword.toLowerCase())
        });
        // if (searchWord ==="")
        if(keyword ===""){
            setfilteredData([]);
            
        }else{
            setfilteredData(newFilter);
        }
        
    }
    const clearInput =()=>{
        setfilteredData([]);
        setwordEntered("");
    }
  return (
    <div className="search">
    <div className='searchInputs'>
        {/* <div className="hero__search__categories">
            All Categories
            <span className="arrow_carrot-down"></span>
        </div> */}
        <input type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter}/>
        {/* <button type="submit" className='site-btn'>SEARCH</button> */}
        <div className='searchIcon'>
            {filteredData.length == 0 ? <SearchIcon/>:<CloseIcon id="clearBtn" onClick={clearInput}/>}
            
        </div>
    </div>
        { filteredData.length !=0 && (
        <div className='dataResult'>
            {
                filteredData.slice(0,15).map(value=>(
                    <div className='searchlist' key={value._id}>
                        <a className='dataItem' href={`/products/${value._id}`} target="_parent">
                        {/* <a className='dataItem' href={`/categoryProducts/?q=${value.name}`}> */}
                            <p>{value.name}</p>
                            
                        </a>
                        {   value.category ==='Blouse' ? 
                                <a className='dataItem' href={`/categoryBlouse?q=${value.category}`}>
                                    { value.category.length !=1 ? <p>{value.category} </p>: <p></p> }
                                </a>:
                            value.category === 'Dress' ?
                                <a className='dataItem' href={`/categoryDress?q=${value.category}`}>
                                    <p>{value.category} </p>
                                </a>:
                            value.category === 'Throw-on'?
                                <a className='dataItem' href={`/categoryThrowOn?q=${value.category}`}>
                                    <p>{value.category} </p>
                                </a>:
                            value.category === 'T-shirt'?
                                <a className='dataItem' href={`/categoryTshirts?q=${value.category}`}>
                                    <p>{value.category} </p>
                                </a>:
                            value.category === 'Bottom'?
                                <a className='dataItem' href={`/categoryTshirts?q=${value.category}`}>
                                    <p>{value.category} </p>
                                </a>:
                                <a className='dataItem' href={`/categoryProducts?q=${value.category}`}>
                                    <p>{value.category} </p>
                                </a>
                        }
                        {/* <Link to={`/products/${value._id}`}>
                            <p>{value.name}</p>
						</Link> */}
                        
                    </div>
                ))
            }
        </div>
        )}
 </div>
  )
}

export default SearchBox3